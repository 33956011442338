import tree2json from './tree2json';

const contentType = 'application/json;charset=utf-8;';

const downloadTree = (input_tree: Object) => {
  const tree = tree2json(input_tree);
  const tree_str = JSON.stringify(tree);
  const filename = tree.name + '-treetodo.json';

  const a = document.createElement('a');
  a.download = filename;
  a.href = `data:${contentType},${encodeURIComponent(tree_str)}`;
  a.target = '_blank';
  a.click();
};

export default downloadTree;
