import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
// import { useRecoilValue } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import Box from '@material-ui/core/Box';

import FileUploader from './FileUploader';
import MyButton from '../components/MyButton';
import { Store } from '../store/context';
import { editorState } from '../store/editorState';
import { informationState } from '../store/informationState';
import json2tree from '../utils/json2tree';
import downloadTree from '../utils/downloadTree';
import getStatusMessage from '../utils/getStatusMessage';
import { deleterState } from '../store/deleterState';
import statics from '../statics';
import { addParentState } from '../store/addParentState';

type InformationType = {};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    backgroundColor: '#444444',
    color: 'white',
    borderRadius: '0em',
  },
  row: {
    // borderColor: 'black',
  },
  title: {
    borderColor: '#333',
    color: 'white',
  },
  cell: {
    borderColor: '#333',
    color: '#dddddd',
    paddingRight: '0px',
    marginRight: '0px',
  },
});

const createData = (name: string, value: string) => {
  return { name, value };
};

const Information = React.memo((props: InformationType) => {
  const { state, dispatch } = useContext(Store);
  const setShowEditor = useSetRecoilState(editorState);
  const setShowAddParent = useSetRecoilState<boolean>(addParentState);
  const setDeleter = useSetRecoilState<boolean>(deleterState);
  const classes = useStyles();
  const [showInformation, setShowInfo] =
    useRecoilState<boolean>(informationState);

  const updateTree = (newTree: any) => {
    const buf_tree = json2tree(newTree);
    dispatch({
      type: 'updateTree',
      payload: buf_tree,
    });
  };

  const closeInformationButton = (
    <MyButton title="hidden info" onClick={() => setShowInfo(false)}>
      <VisibilityOffIcon />
    </MyButton>
  );
  const openEditorButton = (
    <MyButton title="edit task" onClick={() => setShowEditor(true)}>
      <EditIcon />
    </MyButton>
  );
  const addParentButton = (
    <MyButton title="add parent task" onClick={() => setShowAddParent(true)}>
      <FaceIcon />
    </MyButton>
  );
  const deleteButton = (
    <MyButton title="add child task" onClick={() => setDeleter(true)}>
      <DeleteIcon />
    </MyButton>
  );
  const downloadButton = (
    <MyButton
      title="download todo tree"
      onClick={() => downloadTree(state.tree)}
    >
      <GetAppIcon />
    </MyButton>
  );
  const uploadButton = (
    <Box m={statics.informationButtonMargin}>
      <FileUploader updateJSONTree={updateTree} title="set local treetodo" />
    </Box>
  );
  const openInformationButton = (
    <MyButton title="hidden info" onClick={() => setShowInfo(true)}>
      <VisibilityIcon />
    </MyButton>
  );

  const addChild = () => {
    dispatch({
      type: 'addChild',
    });
    setShowEditor(true);
  };
  const addChildButton = (
    <MyButton title="add child task" onClick={addChild}>
      <ChildCareIcon />
    </MyButton>
  );

  const buttons = (
    <Grid container>
      {closeInformationButton}
      {openEditorButton}
      {addParentButton}
      {addChildButton}
      {deleteButton}
      {downloadButton}
      {uploadButton}
    </Grid>
  );

  const rows = [
    createData('Detail', state.task.detail),
    createData('Issue', state.task.issue),
    createData('Status', getStatusMessage(state.task)),
  ];

  return (
    <>
      {showInformation ? (
        <TableContainer component={Paper} className={classes.table}>
          {buttons}
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.title}>Task</TableCell>
                <TableCell align="left" className={classes.title}>
                  {state.task.name}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name} className={classes.row}>
                  <TableCell scope="row" className={classes.cell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper} className={classes.table}>
          <>{openInformationButton}</>
        </TableContainer>
      )}
    </>
  );
});

export default Information;
