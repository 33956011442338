import React from 'react';
import { Switch, Route } from 'react-router-dom';

import styled from '@emotion/styled';
import NavBar from './components/NavBar';
import About from './components/About';
import Editor from './components/Editor';
import Information from './components/Information';
import CustomTaskMenu from './components/CustomTaskMenu';
import TreeToDo from './components/TreeToDo';
import DeleteProvider from './components/DeleteProvider';
import AddParentProvider from './components/AddParentProvider';

const Sdiv = styled.div`
  font-family: 'Ubuntu Mono', monospace;
`;

const App: React.FC<{}> = () => {
  return (
    <>
      <NavBar />
      <Switch>
        <Route exact path="/about" component={About} />
        <Route path="/">
          <Sdiv>
            <DeleteProvider />
            <AddParentProvider />
            <TreeToDo />

            <Information />
            <Editor />
            <CustomTaskMenu />
          </Sdiv>
        </Route>
      </Switch>
    </>
  );
};

export default App;
