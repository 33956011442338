import statics from '../statics';
import { taskType } from '../store/taskState';

const generateTask = (props: any): taskType => {
  return {
    name: props.name,
    parentTask: props.parentTask,
    detail: props.hasOwnProperty('detail') ? props.detail : '',
    issue: props.hasOwnProperty('issue') ? props.issue : '',
    untouched: true,
    completed: false,
    working: false,
    troubled: false,
    pending: false,
    discontinue: false,
    //textProps: {x: -props.name.length*5, y: 20},
    textProps: { x: statics.textPosition.x, y: statics.textPosition.y },
    gProps: { className: 'default-node' },
    pathProps: { className: 'dotted-line' },
    children: props.hasOwnProperty('children')
      ? props.children
      : ([] as taskType[]),
  };
};

export default generateTask;
