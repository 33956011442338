import statics from '../statics';
import { taskType } from '../store/taskState';

const json2tree = (input_tree: any) => {
  const buf_tree = Object.assign({}, input_tree);
  buf_tree.parentTask = 'null';
  buf_tree.textProps = {
    x: statics.textPosition.x,
    y: statics.textPosition.y,
  };
  buf_tree.gProps = {
    className: 'default-node',
  };
  buf_tree.pathProps = {
    className: 'solid-line',
  };
  const convertByDFS = (node: taskType) => {
    if (!node) return;
    if (!node.children) return;
    for (let i = 0; i < node.children.length; ++i) {
      node.children[i].parentTask = node.name;
      node.children[i].textProps = {
        x: statics.textPosition.x,
        y: statics.textPosition.y,
      };
      node.children[i].gProps = {
        className: node.children[i].completed
          ? 'completed-node'
          : node.children[i].working
          ? 'working-node'
          : node.children[i].troubled
          ? 'troubled-node'
          : node.children[i].pending
          ? 'pending-node'
          : 'default-node',
      };
      node.children[i].pathProps = {
        className: node.children[i].completed
          ? 'solid-line'
          : node.children[i].discontinue
          ? 'discontinue-line'
          : 'dotted-line',
      };
      convertByDFS(node.children[i]);
    }
  };
  convertByDFS(buf_tree);
  return buf_tree;
};

export default json2tree;
