const taskExist = (tree: any, nodeKey: any) => {
  function searching(node: any, nodeKey: any) {
    if (node.name === nodeKey) {
      return node;
    }
    for (let i = 0; i < node.children.length; ++i) {
      let res = searching(node.children[i], nodeKey);
      if (res) {
        return true;
      }
    }
    return false;
  }
  let res = searching(tree, nodeKey);
  return res;
};

export default taskExist;
