import taskExist from './taskExist';

const generateNewName = (tree: any) => {
  let i = 0;
  while (taskExist(tree, 'newtask' + i.toString())) {
    ++i;
  }
  return 'newtask' + i.toString();
};

export default generateNewName;
