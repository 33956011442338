// アプリのメインとなるTreeの管理を行う
// Treeに対する操作はできるだけココで管理したい．

import React, { useEffect, useReducer } from 'react';

import statics from '../statics';
import generateTask from '../utils/generateTask';
import json2tree from '../utils/json2tree';
import findTask from '../utils/findTask';
import generateNewName from '../utils/generateNewName';
import { taskType } from './taskState';

if (!localStorage.getItem(statics.APPKEY)) {
  const buf_tree = generateTask({ name: 'my project', parentTask: 'null' })!;
  buf_tree['detail'] = 'Please right click on node!';
  localStorage.setItem(statics.APPKEY, JSON.stringify(buf_tree));
}

let initialState = {
  tree: json2tree(JSON.parse(localStorage.getItem(statics.APPKEY)!)),
  task: {
    name: 'Thank you access this web app!',
    detail: 'Please Click on task node',
    issue: 'Please right click on task node',
  },
};

const Store = React.createContext<any>(initialState);

const Provider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({
      type: 'setTask',
      payload: state.tree,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};

const updateLocalStorage = (tree: taskType) => {
  localStorage.setItem(statics.APPKEY, JSON.stringify(tree));
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'selectTask': // select task by task name
      state.task = findTask(state.tree, action.payload);
      break;
    case 'setTask':
      state.task = action.payload;
      break;
    case 'updateTask':
      if (!action.payload.name) break;
      if (!action.payload.detail) break;
      if (!action.payload.issue) break;
      if (!action.payload.untouched) break;
      if (!action.payload.completed) break;
      if (!action.payload.working) break;
      if (!action.payload.troubled) break;
      if (!action.payload.pending) break;
      if (!action.payload.discontinue) break;
      state.task.name = action.payload.name;
      state.task.detail = action.payload.detail;
      state.task.issue = action.payload.issue;
      state.task.untouched = action.payload.untouched;
      state.task.completed = action.payload.completed;
      state.task.working = action.payload.working;
      state.task.troubled = action.payload.troubled;
      state.task.pending = action.payload.pending;
      state.task.discontinue = action.payload.discontinue;
      updateLocalStorage(state.tree);
      break;
    case 'addParent':
      break;
    case 'addChild':
      console.log('adding child');
      let new_task = generateTask({
        name: generateNewName(state.tree),
        parentTask: state.task.name,
      });
      state.task.children.push(new_task!);
      state.task = new_task;
      updateLocalStorage(state.tree);
      break;
    case 'updateTree':
      state.tree = action.payload;
      updateLocalStorage(state.tree);
      break;
    // case 'pushTree':
    //   break;
    // case 'pullTree':
    //   state.tree = action.payload;
    //   updateLocalStorage(state.tree);
    //   break;
    default:
      throw new Error();
  }
  return { ...state };
}

export { Store, Provider };
