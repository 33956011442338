import React, { useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { Tooltip } from '@material-ui/core';

type FileUploaderType = {
  updateJSONTree: (newTree: {}) => void;
  title?: string;
};

const FileUploader = (props: FileUploaderType) => {
  const [show, setShow] = useState<boolean>(false);

  // click submit button
  const handleSave = (acceptedFiles: Array<File>) => {
    setShow(false);
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onload = () => {
        const str = reader.result as string;
        var newTree = JSON.parse(str);
        props.updateJSONTree(newTree);
      };

      reader.readAsText(file);
    });
  };

  return (
    <>
      {props.title ? (
        <Tooltip title={props.title}>
          <Button variant="contained" onClick={() => setShow(true)}>
            <PublishIcon />
          </Button>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShow(true)}
        >
          <PublishIcon />
        </Button>
      )}

      <DropzoneDialog
        open={show}
        onSave={handleSave}
        acceptedFiles={['.json']}
        showPreviews={true}
        filesLimit={1}
        Icon={PublishIcon}
        maxFileSize={5000000}
        onClose={() => setShow(false)}
      />
    </>
  );
};

export default FileUploader;
