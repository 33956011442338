import React, {
  // useContext,
  useCallback,
  // useMemo
} from 'react';
// import { useRecoilState } from 'recoil';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import useStyles from '../styles/materials';
import logo from '../logo/TreeToDo-logo.png';

import {
  AppBar,
  Toolbar,
  // IconButton,
  Typography,
  // InputBase,
  // Badge,
  Box,
  // Link,
  // MenuItem,
  // Menu,
  CssBaseline,
  Divider,
  Button,
  // ListItemIcon,
} from '@material-ui/core';

import // Menu as MenuIcon, // Hamburger icon
// Search as SearchIcon,
// AccountCircle,
// Mail as MailIcon, // mail icon
// Notifications as NotificationsIcon,
// MoreVert as MoreIcon,
// AccountBox as AccountBoxIcon,
//Grade as GradeIcon,
// Settings as SettingsIcon,
// ExitToApp as ExitToAppIcon,
'@material-ui/icons';

const style = {
  background: '#3e3e3e',
};

const Navigation = React.memo(() => {
  let history = useHistory();
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  // const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // }, []);

  // const handleMobileMenuClose = useCallback(() => {
  //   setMobileMoreAnchorEl(null);
  // }, []);

  // const handleMenuClose = useCallback(() => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // }, []); // eslint-disable-line

  // const handleMobileMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // }, []);

  const handleRedirect = useCallback((path: string) => {
    history.push(path);
  }, []); // eslint-disable-line

  // const handleRedirectDesktopMenu = useCallback((path:string) => {
  //   history.push(path);
  //   handleMenuClose();
  // }, []); // eslint-disable-line

  // const handleRedirectMobileMenu = useCallback((path:string) => {
  //   history.push(path);
  //   handleMobileMenuClose();
  // }, []); //eslint-disable-line

  // const menuId = 'primary-search-account-menu';
  // const renderMenu = useMemo(() => {
  //   return <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem>
  //       <ListItemIcon><AccountBoxIcon /></ListItemIcon>
  //       <Typography variant="inherit">Profile</Typography>
  //     </MenuItem>
  //     <Divider variant="inset" />
  //     <Divider variant="inset" />
  //     <MenuItem onClick={() => handleRedirectDesktopMenu("/settings")}>
  //       <ListItemIcon><SettingsIcon  /></ListItemIcon>
  //       <Typography variant="inherit">Settings</Typography>
  //     </MenuItem>
  //     <Divider variant="inset" />
  //   </Menu>
  // }, [anchorEl, isMenuOpen]); //eslint-disable-line

  // const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = useMemo( () => {
  //   return <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     {/* <MenuItem onClick={() => handleRedirectMobileMenu("/about")}>ABOUT</MenuItem> */}
  //     {/* <MenuItem onClick={() => handleRedirectMobileMenu("/features")}>FEATURES</MenuItem> */}
  //   </Menu>
  // }, [isMobileMenuOpen, mobileMoreAnchorEl]); // eslint-disable-line

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AppBar position="fixed" style={style}>
        <Toolbar>
          <Box m={1}>
            <img
              src={logo}
              onClick={() => handleRedirect('/')}
              width="50px"
              alt="logo"
            />
          </Box>
          <Typography
            className={classes.title}
            onClick={() => handleRedirect('/')}
            variant="h5"
            style={{ cursor: 'pointer' }}
            noWrap
          >
            TreeToDo
          </Typography>

          <Divider orientation="vertical" style={{ margin: '7px' }} />
          <Button component={RouterLink} color="inherit" to="/about">
            About
          </Button>
          {/* <Button component={RouterLink} color="inherit" to="/features">Features</Button> */}

          {/* <div className={classes.grow} />
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          <Divider orientation="vertical" style={{margin:"7px"}} />
          </IconButton> */}

          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
    </div>
  );
});

export default Navigation;
