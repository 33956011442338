import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import statics from '../statics';

// template of information bar button.
const MyButton: React.FC<any> = ({ title, onClick, children }) => {
  return (
    <Box m={statics.informationButtonMargin}>
      <Tooltip title={title}>
        <Button variant="contained" onClick={onClick}>
          {children}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default MyButton;
