import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { editorState } from '../store/editorState';
import { menuState } from '../store/menuState';
import { Store } from '../store/context';
import { deleterState } from '../store/deleterState';
import { addParentState } from '../store/addParentState';

type CusTomTaskMenuProps = {};

const CustomTaskMenu: React.FC<CusTomTaskMenuProps> = React.memo(() => {
  const { dispatch } = useContext(Store);
  const [menuAnchorEl, setMenuAnchorEl] = useRecoilState(menuState);
  const setShowEditor = useSetRecoilState(editorState);
  const setShowAddParent = useSetRecoilState<boolean>(addParentState);
  const setShowDeleter = useSetRecoilState<boolean>(deleterState);

  const handleEdit = () => {
    setShowEditor(true);
    setMenuAnchorEl(null);
  };

  const handleAddParent = () => {
    setShowAddParent(true);
    setMenuAnchorEl(null);
  };

  const addChild = () => {
    dispatch({
      type: 'addChild',
    });
    setShowEditor(true);
  };

  const handleAddChild = () => {
    addChild();
    setMenuAnchorEl(null);
  };

  const handleDelete = () => {
    setShowDeleter(true);
    setMenuAnchorEl(null);
  };

  return (
    <Menu
      id="custom-menu"
      keepMounted
      anchorEl={menuAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(menuAnchorEl)}
      onClose={() => setMenuAnchorEl(null)}
    >
      <MenuItem disabled={true}>Your Action?</MenuItem>
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleAddParent}>Add Parent</MenuItem>
      <MenuItem onClick={handleAddChild}>Add Child</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
  );
});

export default CustomTaskMenu;
