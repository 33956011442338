//TODO: return type rewrite to string (empty string mean false)
const findTask = (tree: Object, nodeKey: string): any => {
  //TODO: rewrite with IIFE
  const recurser = (node: any, nodeKey: string): any => {
    if (node.name === nodeKey) {
      return node;
    }
    for (let i = 0; i < node.children.length; ++i) {
      let res = recurser(node.children[i], nodeKey);
      if (res) {
        return res;
      }
    }
    return false;
  };
  let res = recurser(tree, nodeKey);
  if (!res) alert('oops, can you reload browser?');
  return res;
};

export default findTask;
