import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { addParentState } from '../store/addParentState';
import findTask from '../utils/findTask';
import generateTask from '../utils/generateTask';
import { Store } from '../store/context';
import statics from '../statics';
import generateNewName from '../utils/generateNewName';

import { taskType } from '../store/taskState';
import { editorState } from '../store/editorState';

const AddParentProvider: React.FC<{}> = () => {
  const { state, dispatch } = useContext(Store);
  const setShowEditor = useSetRecoilState<boolean>(editorState);
  const [showAddParentr, setShowAddParentr] =
    useRecoilState<boolean>(addParentState);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const addParent = (tree: taskType, task: taskType) => {
    let current = task;
    let old_parent = findTask(tree, task.parentTask!);

    let new_parent = generateTask({
      name: generateNewName(tree),
      parentTask: task.parentTask,
      children: [current],
    });
    current.parentTask = new_parent.name;

    for (let i = 0; i < old_parent.children.length; ++i) {
      if (old_parent.children[i].name === task.name) {
        old_parent.children[i] = new_parent;
      }
    }

    console.log('new parent: ', new_parent);
    dispatch({
      type: 'setTask',
      payload: new_parent,
    });
    localStorage.setItem(statics.APPKEY, JSON.stringify(state.tree));
    setShowAddParentr(false);
    setShowEditor(true);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showAddParentr}
        onClose={() => setShowAddParentr(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Do you add parent to "{state.task.name}"?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be returned.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowAddParentr(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => addParent(state.tree, state.task)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddParentProvider;
