const getStatusMessage = (task: any) => {
  return task.untouched
    ? 'untouched'
    : task.completed
    ? 'completed'
    : task.working
    ? 'working'
    : task.troubled
    ? 'troubled'
    : task.pending
    ? 'pending'
    : task.discontinue
    ? 'discontinue'
    : 'none';
};

export default getStatusMessage;
