import React, { useContext, useEffect, useState } from 'react';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Tree from 'react-tree-graph';
import 'react-tree-graph/dist/style.css';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { informationState } from '../store/informationState';
//import findTask from '../utils/findTask';
//import {animated} from 'react-spring';
//import {easeExpOut} from 'd3-ease';

import { Store } from '../store/context';
import { taskType } from '../store/taskState';
import { menuState } from '../store/menuState';
import statics from '../statics';
// import '../styles/Tree.scss';
import '../styles/Tree.css';

//TODO: storeからtreeをロードし，そこにクリックハンドラを配置して描画するようにする
// それにより，すべてのデータをStoreおよびRecoilで共有できるようになる
const TreeToDo: React.FC<any> = (props) => {
  const { state, dispatch } = useContext(Store); // eslint-disable-line
  const showInformation = useRecoilValue(informationState);

  const setMenuAnchorEl = useSetRecoilState(menuState);
  const [windowSize, setWindowSize] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const dynamicHeightRate = showInformation
    ? statics.displayRate.treeHeight
    : statics.displayRate.treeMaxHeight;

  const selectTask = (taskname: string) => {
    dispatch({
      type: 'selectTask',
      payload: taskname,
    });
  };

  const handleClick = (event: any, nodeKey: string) => {
    // event.preventDefault();
    selectTask(nodeKey);
  };

  const handleRightClick = (event: React.MouseEvent<any>, nodeKey: string) => {
    event.preventDefault();
    selectTask(nodeKey);
    setMenuAnchorEl(event.currentTarget);
  };

  const setClickHandler = (
    task: taskType,
    handleClick: any,
    handleRightClick: any
  ) => {
    if (!task) return;
    if (!task.gProps) task.gProps = {};
    task.gProps = {
      className: task.untouched
        ? 'default-node'
        : task.completed
        ? 'completed-node'
        : task.working
        ? 'working-node'
        : task.troubled
        ? 'troubled-node'
        : task.pending
        ? 'pending-node'
        : 'default-node',
      onClick: handleClick,
      onContextMenu: handleRightClick,
    };
    task.pathProps = {
      className: task.completed
        ? 'solid-line'
        : task.discontinue
        ? 'discontinue-line'
        : 'dotted-line',
    };
  };

  const setClickHandlerByDFS = (
    tree: taskType,
    handleClick: any,
    handleRightClick: any
  ) => {
    if (!tree) return;
    setClickHandler(tree, handleClick, handleRightClick);
    tree.children.forEach((task: taskType) => {
      setClickHandlerByDFS(task, handleClick, handleRightClick);
    });
  };

  useEffect(() => {
    setClickHandlerByDFS(state.tree, handleClick, handleRightClick);
    dispatch({
      type: 'updateTree',
      payload: state.tree,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tree]); //TODO: change depend

  useEffect(() => {
    setClickHandler(state.task, handleClick, handleRightClick);
    dispatch({
      type: 'updateTask',
      payload: state.task,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.task,
    state.task.name,
    state.task.detail,
    state.task.issue,
    state.task.untouched,
    state.task.completed,
    state.task.working,
    state.task.troubled,
    state.task.pending,
  ]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, []);

  return (
    <div className="treetodo">
      <Tree
        data={state.tree}
        margins={{ bottom: 10, left: 20, right: 150, top: 10 }}
        height={windowSize.height * dynamicHeightRate}
        width={windowSize.width * statics.displayRate.treeWidth}
        nodeRadius={7}
        //TODO: add following part in <Tree > options: inner bug
        //animated
        //duration={1000}
        //easing={easeExpOut}
      />
    </div>
  );
};

export default TreeToDo;
