const statics = {
  APPKEY: 'TreeToDo-project-tree',
  textPosition: {
    x: 0,
    y: 0,
  },
  displayRate: {
    treeMaxHeight: 0.9,
    treeHeight: 0.67,
    //treeWidth: 1.00,
    treeWidth: 0.95,
    initInfo: 0.3,
  },
  informationButtonMargin: 1,
};

export default statics;
