import React, { useContext } from 'react';
import { useRecoilState } from 'recoil';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import findTask from '../utils/findTask';
import generateTask from '../utils/generateTask';
import json2tree from '../utils/json2tree';
import { deleterState } from '../store/deleterState';
import { Store } from '../store/context';
import statics from '../statics';

const DeleteProvider: React.FC<{}> = () => {
  const { state, dispatch } = useContext(Store);
  const [showDeleter, setShowDeleter] = useRecoilState<boolean>(deleterState);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const deleteTask = () => {
    if (state.task.parentTask === 'null') {
      alert('This Task is project root.');
      // return;
    }
    if (state.task.parentTask === 'null') {
      const new_tree = generateTask({
        name: 'my project',
        parentTask: 'null',
        detail: 'Please right click on node',
      });
      const buf_tree = json2tree(new_tree);
      dispatch({
        type: 'updateTree',
        payload: buf_tree,
      });
      dispatch({
        type: 'setTask',
        payload: buf_tree,
      });
    } else {
      let parentTask = findTask(state.tree, state.task.parentTask);
      let from = 0;
      let to = 0;
      let buf = [];
      for (from = 0; from < parentTask.children.length; ++from) {
        if (parentTask.children[from].name === state.task.name) {
        } else {
          buf[to] = parentTask.children[from];
          to++;
        }
      }
      parentTask.children = buf;
      dispatch({
        type: 'updateTask',
        payload: state.tree,
      });
      localStorage.setItem(statics.APPKEY, JSON.stringify(state.tree));
      setShowDeleter(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showDeleter}
        onClose={() => setShowDeleter(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Do you delete task "{state.task.name}"?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be returned.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowDeleter(false)}
            color="primary"
          >
            No
          </Button>
          <Button onClick={deleteTask} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteProvider;
