import React from 'react';
import Box from '@material-ui/core/Box';

type AboutProps = {};

const About: React.FC<AboutProps> = React.memo(() => {
  return (
    <Box m={4}>
      <article>
        <div className="prose dark:prose-dark">
          <h1 className="flex" id="about-page">
            About Page
          </h1>
          <p>Welcome to the about page</p>
          <h2 className="flex" id="本ページについて">
            本ページについて
          </h2>
          <p>本ページには，本アプリについて記載しています．</p>
          <p>
            また，本ページの内容は急に変更する可能性がございますので，ご了承ください．
          </p>
          <h2 className="flex" id="本アプリについて">
            本アプリについて
          </h2>
          <p>
            本アプリでは，ソフトウェア開発などにおけるプロジェクト管理を木構造で描画してチェックするアプリとなっております．
          </p>
          <p>どうぞよろしくおねがいします．</p>
          <h2 className="flex" id="プライバシーポリシー">
            プライバシーポリシー
          </h2>
          <p>
            本アプリでは，Googleによるアクセス解析ツールである
            <a
              href="https://analytics.google.com/analytics/web/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics
            </a>
            を利用しています．
          </p>
          <p>
            Google
            Analyticsでは，アプリへのトラフィックデータ収集のために，Cookie等の情報がGoogleに送信されます．
          </p>
          <p>この情報は個人を特定できるものではありません．</p>
          <p>
            この規約に関しては，
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            を利用しています．でご覧いただけます．
          </p>
          <h3 className="flex" id="当アプリでgoogle-analyticsを使う理由">
            当アプリでGoogle Analyticsを使う理由
          </h3>
          <p>
            アプリへのアクセスを可視化することにより，アプリのよく使われる機能を把握し，アプリを改善する事に役立てています．
          </p>
          <h3 className="flex" id="cookieの拒否について">
            Cookieの拒否について
          </h3>
          <p>Cookieの使用を拒否する場合は，ブラウザの設定等で設定できます．</p>
          <p>
            Google Chromeをお使いの方は
            <a
              href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            をご覧ください．
          </p>
          <h2 className="flex" id="免責事項">
            免責事項
          </h2>
          <p>
            当アプリでは現状，各ブラウザ内でデータを管理しており，利用データを外部へ通信する機能はございません．
            今後の更新によって変更する可能性がございますこと，ご了承ください．
          </p>
          <p>
            また，ローカル管理からクラウド管理に移行する際に，ローカルストレージからの読み取りで不具合が発生する可能性がございます．
          </p>
          <p>
            本アプリを使ってくださる方は，定期的にツリーデータをダウンロードしてお使いいただけますよう，よろしくおねがいします．
          </p>
          <p>
            またその際に，同意なく本ページ内容を変更する可能性がございますこと，併せてご了承ください．
          </p>
          <p>
            当サイトに掲載された内容およびリンクやバナーなどによって生じた損害等の一切の責任を負いかねますのでご了承ください．
          </p>
          <h2 className="flex" id="使用ライブラリについて">
            利用技術について
          </h2>
          <p>
            本アプリは，
            <a
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            で開発し，
            <a
              href="https://firebase.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase
            </a>
            でホスティングしています．
          </p>
        </div>
      </article>
    </Box>
  );
});

export default About;
