const tree2json = (input_tree: any) => {
  const buf_tree = JSON.parse(JSON.stringify(input_tree));

  //TODO: rewrite with IIFE
  const convertByDfs = (node: any) => {
    for (let i = 0; i < node.children.length; ++i) {
      delete buf_tree.parentTask;
      delete buf_tree.textProps;
      delete buf_tree.gProps;
      delete buf_tree.pathProps;
      convertByDfs(node.children[i]);
    }
  };
  convertByDfs(buf_tree);

  return buf_tree;
};

export default tree2json;
