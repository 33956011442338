import React, { useState, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Store } from '../store/context';
import { editorState } from '../store/editorState';

type EditorProps = {};

const Editor: React.FC<EditorProps> = React.memo(() => {
  const { state, dispatch } = useContext(Store);
  const [showEditor, setShowEditor] = useRecoilState(editorState);

  // ユーザ編集用にローカルステートを準備
  const [name, setName] = useState(state.task.name);
  const [detail, setDetail] = useState(state.task.detail);
  const [issue, setIssue] = useState(state.task.issue);
  const [untouched, setUntouched] = useState(state.task.untouched);
  const [completed, setCompleted] = useState(state.task.completed);
  const [working, setWorking] = useState(state.task.working);
  const [troubled, setTroubled] = useState(state.task.troubled);
  const [pending, setPending] = useState(state.task.pending);
  const [discontinue, setDiscontinue] = useState(state.task.discontinue);

  // 編集モードに入るタイミングで編集用ステートを更新
  // 常にトラッキングする必要はないため，`showEditor`のみに依存とする．
  useEffect(() => {
    setName(state.task.name);
    setDetail(state.task.detail);
    setIssue(state.task.issue);
    setUntouched(state.task.untouched);
    setCompleted(state.task.completed);
    setWorking(state.task.working);
    setTroubled(state.task.troubled);
    setPending(state.task.pending);
    setDiscontinue(state.task.discontinue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditor]);

  const nameHandler = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setName(event.currentTarget.value);
  const detailHandler = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setDetail(event.currentTarget.value);
  const issueHandler = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setIssue(event.currentTarget.value);

  const assignAllFalse = () => {
    setUntouched(false);
    setCompleted(false);
    setWorking(false);
    setTroubled(false);
    setPending(false);
    setDiscontinue(false);
  };

  const untouchedHandler = (event: any) => {
    assignAllFalse();
    setUntouched(true);
  };
  const completedHandler = (event: any) => {
    assignAllFalse();
    setCompleted(true);
  };
  const workingHandler = (event: any) => {
    assignAllFalse();
    setWorking(true);
  };
  const troubledHandler = (event: any) => {
    assignAllFalse();
    setTroubled(true);
  };
  const pendingHandler = (event: any) => {
    assignAllFalse();
    setPending(true);
  };
  const discontinueHandler = (event: any) => {
    assignAllFalse();
    setDiscontinue(true);
  };
  const saveStatus = (event: any) => {
    dispatch({
      type: 'updateTask',
      payload: {
        name: name,
        detail: detail,
        issue: issue,
        untouched: untouched,
        completed: completed,
        working: working,
        troubled: troubled,
        pending: pending,
        discontinue: discontinue,
      },
    });
    event.preventDefault();
    setShowEditor(false);
  };

  const statusChecker = (
    <List dense component="div" role="list">
      <ListItem key="untouched" role="listitem" button>
        <ListItemIcon onClick={untouchedHandler}>
          <Checkbox
            checked={untouched}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-untouched-label',
            }}
          />
        </ListItemIcon>
        <ListItemText
          id="untouched"
          primary={`untouched`}
          onClick={untouchedHandler}
        />
      </ListItem>
      <ListItem key="completed" role="listitem" button>
        <ListItemIcon>
          <Checkbox
            checked={completed}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-completed-label',
            }}
            onChange={completedHandler}
          />
        </ListItemIcon>
        <ListItemText
          id="completed"
          primary={`completed`}
          onClick={completedHandler}
        />
      </ListItem>
      <ListItem key="working" role="listitem" button>
        <ListItemIcon>
          <Checkbox
            checked={working}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-working-label',
            }}
            onChange={workingHandler}
          />
        </ListItemIcon>
        <ListItemText
          id="working"
          primary={`working`}
          onClick={workingHandler}
        />
      </ListItem>
      <ListItem key="troubled" role="listitem" button>
        <ListItemIcon>
          <Checkbox
            checked={troubled}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-troubled-label',
            }}
            onChange={troubledHandler}
          />
        </ListItemIcon>
        <ListItemText
          id="troubled"
          primary={`troubled`}
          onClick={troubledHandler}
        />
      </ListItem>
      <ListItem key="pending" role="listitem" button>
        <ListItemIcon>
          <Checkbox
            checked={pending}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-pending-label',
            }}
            onChange={pendingHandler}
          />
        </ListItemIcon>
        <ListItemText
          id="pending"
          primary={`pending`}
          onClick={pendingHandler}
        />
      </ListItem>
      <ListItem key="discontinue" role="listitem" button>
        <ListItemIcon>
          <Checkbox
            checked={discontinue}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': 'transfer-list-all-item-discontinue-label',
            }}
            onChange={discontinueHandler}
          />
        </ListItemIcon>
        <ListItemText
          id="discontinue"
          primary={`discontinue`}
          onClick={discontinueHandler}
        />
      </ListItem>
    </List>
  );

  return (
    <div>
      <Dialog
        onClose={() => setShowEditor(false)}
        aria-labelledby="customized-dialog-title"
        open={showEditor}
      >
        <DialogTitle id="customized-dialog-title">Information Edit</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Task Name"
            type="text"
            defaultValue={name}
            onChange={nameHandler}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="detail"
            label="Detail"
            type="text"
            defaultValue={detail}
            onChange={detailHandler}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="issue"
            label="Issue"
            type="text"
            defaultValue={issue}
            onChange={issueHandler}
            fullWidth
          />
          <div>{statusChecker}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditor(false)} color="primary">
            CANCEL
          </Button>
          <Button autoFocus onClick={saveStatus} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Editor;
